<template>
  <div>
  <div>
      <div class="flex justify-between">
        <p class="mt-3 font-bold">header</p>
      </div>
      <div class="my-4">
        <rich-editor v-model="header" :value="header" :disabled="false" />
      </div>
  </div>
  <div  
    v-for="(p, i) in paragraphs"
    :key="i">
      <div class="flex justify-between">
        <p class="mt-3 font-bold">paragraph {{i+1}}</p>
        <div class="flex">
            <button
                class="text-red-700 focus:outline-none"
                type="button"
                v-on:click="removeParagrah(i)"
            >
                <icon name="trash"  class="w-8 h-8 mt-2 ml-2 text-red-500" /> 
            </button>
        </div>
      </div>
      <div class="my-4">
        <rich-editor v-model="paragraphs[i].value" :value="paragraphs[i].value" :disabled="false" />
      </div>
    </div>
    <div v-if="paragraphs.length > 0">
      <button
          class="flex items-center font-bold focus:outline-none button-color mt-4"
          type="button"
          v-on:click="addParagraph()"
      >
        <icon name="plus" class="w-5 h-5 color-text mr-2" /> 
        <p>add new paragraph</p>
      </button>
    </div>
    <div v-else class=" h-60 flex flex-wrap justify-center">
      <button
          class="flex items-center font-bold focus:outline-none button-color mt-4"
          type="button"
          v-on:click="addParagraph()"
      >
        <icon name="plus" class="w-5 h-5 color-text mr-2" /> 
        <p>add new paragraph</p>
      </button>
    </div>
  
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import RichEditor from '@/components/move_in/RichEditor'
import Icon from '@/components/ui/Icon'
import ModalNavigation from '@/mixins/ModalNavigation'

export default {
  mixins: [ AuthMixin, ModalNavigation ],
  components: { RichEditor, Icon },
  data() {
    return{
      paragraphs: [
        {
          value: "The staff at 'Community Name' have been honored to have you as a resident and community member since 'original move-in date'.  We know that you have a choice in where you live, so thank you for choosing to call 'Community Name' home, and thank you for helping to make this such a wonderful community!",
        },
        {
          value: "A review of our records shows that your current lease will expire on 'LeaseEndDate'.  We are happy to present you with several options to assist you in renewing your lease agreement with us.  Please indicate which option works best for you, sign this document, and return it to our office so we can prepare your renewal today.",
        },
        {
          value: "Your current rental rate is <$CurrentRent>.  If you do not renew your lease or give sixty (60) days written notice of your intent to vacate your apartment, then your current lease will continue on a month-to-month basis.  The rental rate for the month-to-month will be the 12-month rate quoted above, plus a <$Month-to-MonthFee> monthly premium.  This new rent amount, plus your month-to-month premium will automatically begin when your current lease expires. ",
        },
        {
          value:"We would like to remind you we value your input! If you have a specific request, concern or suggestion, please stop by the office or leave us a note.  If you would like to discuss this offer, you may contact us in person, via email, or by phone.  You can also access this offer through your Resident Portal at <url>.  There, you can take advantage of the built in chat feature to discuss with us." +
                "Thank you again for being a wonderful part of our community.  We look forward to continuing our relationship!",
        },
      ],
      header: "<p>Lease Renewal Offer Letter</p>" +
        "<p>Unit 'Unit Number'</p>" +
        "<p>Dear  'Resident 1', 'Resident 2', 'Resident 3',</p>",
    };
  },
  methods: {
    addParagraph(){
      this.paragraphs.push({value: ''});
    },
    removeParagrah(i){
      this.paragraphs.splice(i,1);
    }
  },
}
</script>

<style scoped>
.color-text {
  color: var(--highlightColor500);
}
.button-color {
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}
</style>