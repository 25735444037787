<template>
  <vue-editor 
    v-model="content"
    :value="value"
    :editor-toolbar="customToolbar" 
    :disabled="disabled"
    @text-change="updateValue(content)"
    />
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  props: {
    value: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        required: true,
    },
  },
  emits: ['input'],
  data() {
    return{
        content:'',
        customToolbar: [
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
            ["image", "code-block"]
        ],
        event: "update",
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    }
  },
  mounted() {
    this.content = this.value
  }
};
</script>

<style >
.ql-editor {
    min-height: 125px !important;
    font-size: 16px;
}
</style>